import { MatSnackBarConfig } from '@angular/material/snack-bar';

const DURATION = 7000;

export enum SnackbarType {
  success = 'success',
  error = 'error'
}

export class SnackbarConfig {
  static success: MatSnackBarConfig = {
    duration: DURATION,
    panelClass: ['snackbar', SnackbarType.success],
    data: { type: SnackbarType.success }
  };

  static error: MatSnackBarConfig = {
    duration: DURATION,
    panelClass: ['snackbar', SnackbarType.error],
    data: { type: SnackbarType.error }
  };
}
