import { Directive, ElementRef } from '@angular/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class HasAttributesBase<T extends HTMLElement = HTMLElement> {
  get hostElement(): T {
    return this.elementRef.nativeElement;
  }

  protected constructor(protected readonly elementRef: ElementRef<T>) {}

  /** Gets whether the button has one of the given attributes and the attributes values are not false */
  _hasHostAttributes(...attributes: string[]): boolean {
    return attributes.some(
      attribute => !!this._getAttributeValue(attribute) && this._getAttributeValue(attribute) !== 'false'
    );
  }

  /** Gets value of the button attributes or return */
  _getAttributeValue(attribute: string): string | boolean {
    if (this.hostElement.hasAttribute(attribute)) {
      const value = this.hostElement.getAttribute(attribute);
      if (value !== '' && value !== null) {
        return value;
      }
      return true;
    }
    return false;
  }
}
