import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TiimePipesModule } from '@manakincubber/tiime-utils';

import { SearchBarComponent } from './search-bar.component';
import { TiimeIconModule } from '../icon/index';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TiimeIconModule, TiimePipesModule],
  declarations: [SearchBarComponent],
  exports: [SearchBarComponent]
})
export class TiimeSearchBarModule {}
