import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { StepStatusEnum } from '@enums/step-status.enum';

export const ERROR_COMMENT_ADDED = 'Error Comment Added';

export class ErrorCommentAdded implements TrackingEvent {
  readonly name = ERROR_COMMENT_ADDED;

  constructor(
    readonly stepStatus: StepStatusEnum,
    readonly isEmpty: boolean
  ) {}
}
