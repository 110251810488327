import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  ViewEncapsulation
} from '@angular/core';

import { ButtonBase } from './button.base';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[tiime-button], a[tiime-button]',
  templateUrl: './button.component.html',
  styleUrls: ['button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ButtonBase, useExisting: forwardRef(() => ButtonComponent) }]
})
export class ButtonComponent extends ButtonBase {
  private readonly classList: string[] = [
    'primary',
    'warn',
    'accent',
    'neutral',

    'inverted',
    'flat',

    'xxsmall',
    'xsmall',
    'small',
    'big',

    'icon',

    'contrast'
  ];

  constructor(elementRef: ElementRef<HTMLButtonElement>, cdr: ChangeDetectorRef) {
    super(elementRef, cdr);
    this.initButtonClasses();
  }

  private initButtonClasses(): void {
    const itemClasses = this.classList.filter((className: string) => this._hasHostAttributes(className));
    this.elementRef.nativeElement.classList.add(...itemClasses);

    if (!this._hasHostAttributes('contrast')) {
      this.elementRef.nativeElement.classList.add('light');
    }
    if (!this._hasHostAttributes('inverted') && !this._hasHostAttributes('flat')) {
      this.elementRef.nativeElement.classList.add('normal');
    }
  }
}
