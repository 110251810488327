import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GooglePlacesAutocompleteDirective } from './google-places-autocomplete.directive';

export function initializeGooglePlacesModule(googleApiKey: string): void {
  const mapsScript = document.createElement('script');
  mapsScript.type = 'text/javascript';
  mapsScript.async = false;
  mapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
  document.head.appendChild(mapsScript);
}

@NgModule({
  imports: [CommonModule],
  declarations: [GooglePlacesAutocompleteDirective],
  exports: [GooglePlacesAutocompleteDirective]
})
export class TiimeGooglePlacesModule {}
