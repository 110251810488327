import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeSidenavItemComponent } from './sidenav-item.component';
import { TiimeSidenavLayoutComponent } from './sidenav-layout.component';
import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';
import { TiimeTooltipModule } from '../tooltip/index';

@NgModule({
  imports: [CommonModule, TiimeIconModule, TiimeButtonModule, TiimeTooltipModule],
  declarations: [TiimeSidenavLayoutComponent, TiimeSidenavItemComponent],
  exports: [TiimeSidenavLayoutComponent, TiimeSidenavItemComponent]
})
export class TiimeSidenavLayoutModule {}
