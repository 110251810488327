import { inject } from '@angular/core';

import { createTracker, Events, withName } from '@manakincubber/tiime-tracking';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';

import { STEP_STATUS_LABEL } from '@constants/step-status-label.constant';
import { formalitySelector } from '@core/store/formality';
import { Formality } from '@models/formality.model';

import {
  AmplitudeEvents,
  ERROR_COMMENT_ADDED,
  ErrorCommentAdded,
  FORMALITY_CARD_DISPLAYED,
  FORMALITY_CARDS_VIEWED,
  FORMALITY_OPENED,
  FORMALITY_SIGNED,
  FormalityCardDisplayed,
  FormalityCardsViewed,
  FormalityOpened,
  FormalitySigned
} from '../events';
import { AMPLI } from '../tokens';
import { optionsWithCompanyGroupExtra } from '../utils';

export class LegalCreationTrackingGroup {
  private readonly events$ = inject<Events<AmplitudeEvents>>(Events);
  private readonly ampli = inject(AMPLI);
  private readonly store = inject(Store);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  handleFormalitySigned$ = createTracker(
    this.events$.pipe(
      withName(FORMALITY_SIGNED),
      withLatestFrom(this.store.select(formalitySelector)),
      tap(([formalitySigned, formality]: [FormalitySigned, Formality]) => {
        this.ampli.formaliteClientSignee(
          {
            type_de_formalite: 'creation',
            id_business_unit_invitation_formalite: formality?.businessUnitId
          },
          optionsWithCompanyGroupExtra(formality?.companyCreation?.company?.id)
        );
      })
    )
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  handleFormalityOpened$ = createTracker(
    this.events$.pipe(
      withName(FORMALITY_OPENED),
      tap((formalityOpened: FormalityOpened) => {
        this.ampli.formaliteClientAccedee(
          {
            type_de_formalite: 'creation',
            id_business_unit_invitation_formalite: formalityOpened.formality?.businessUnitId
          },
          optionsWithCompanyGroupExtra(formalityOpened.formality?.companyCreation?.company?.id)
        );
      })
    )
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  handleErrorCommentAdded$ = createTracker(
    this.events$.pipe(
      withName(ERROR_COMMENT_ADDED),
      withLatestFrom(this.store.select(formalitySelector)),
      tap(([errorCommentAdded, formality]: [ErrorCommentAdded, Formality]) => {
        this.ampli.formaliteClientCommentee(
          {
            theme_carte_client_formalite: STEP_STATUS_LABEL[errorCommentAdded.stepStatus],
            type_de_formalite: 'creation',
            id_business_unit_invitation_formalite: formality?.businessUnitId,
            commentaire_formalite_vide: errorCommentAdded.isEmpty
          },
          optionsWithCompanyGroupExtra(formality?.companyCreation?.company?.id)
        );
      })
    )
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  handleFormalityCardsViewed$ = createTracker(
    this.events$.pipe(
      withName(FORMALITY_CARDS_VIEWED),
      withLatestFrom(this.store.select(formalitySelector)),
      tap(([formalityCardsViewed, formality]: [FormalityCardsViewed, Formality]) => {
        this.ampli.toutesCartesClientFormaliteVues(
          {
            type_de_formalite: 'creation',
            id_business_unit_invitation_formalite: formality?.businessUnitId
          },
          optionsWithCompanyGroupExtra(formality?.companyCreation?.company?.id)
        );
      })
    )
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  handleFormalityCardsDisplayed$ = createTracker(
    this.events$.pipe(
      withName(FORMALITY_CARD_DISPLAYED),
      withLatestFrom(this.store.select(formalitySelector)),
      tap(([formalityCardDisplayed, formality]: [FormalityCardDisplayed, Formality]) => {
        this.ampli.carteClientFormaliteAffichee(
          {
            theme_carte_client_formalite: STEP_STATUS_LABEL[formalityCardDisplayed.stepStatus],
            type_de_formalite: 'creation',
            id_business_unit_invitation_formalite: formality?.businessUnitId
          },
          optionsWithCompanyGroupExtra(formality?.companyCreation?.company?.id)
        );
      })
    )
  );
}
