<div class="head">
  @if (icon) {
    <img class="icon" [style.width]="maxSize + 'px'" [src]="icon" alt="logo_step" draggable="false" />
  }
  @if (title) {
    <div class="title">{{ title }}</div>
  }
  @if (subtitle) {
    <div class="subtitle">{{ subtitle }}</div>
  }
</div>
