import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf, isDevMode } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TrackingModule, TrackingService } from '@manakincubber/tiime-tracking';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Observable, filter, take, tap } from 'rxjs';

import { ApiInterceptor } from '@interceptors/api.interceptor';
import { SentryService } from '@services/sentry.service';

import { AmplitudeModule } from './amplitude/amplitude.module';
import { AppOpenIdentified } from './amplitude/events';
import { InternalTrackingGroup, LegalCreationTrackingGroup } from './amplitude/groups';
import { AuthModule } from './auth/auth.module';
import { AppStoreState } from './interfaces/app-store-state';
import { User } from './models/user.model';
import { AppConfigService } from './services/app-config.service';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import * as companyConfigReducer from './store/company-config/company-config-reducer';
import { CreationRequestEffects } from './store/creation-request';
import * as creationRequestReducer from './store/creation-request/creation-request-reducer';
import { FormalityEffects } from './store/formality';
import * as formalityReducer from './store/formality/formality-reducer';
import { provideBootstrapEffects } from './store/provide-bootstrap-effects';
import { UserEffects, userSelector } from './store/user';
import * as userReducer from './store/user/user-reducer';

export function bootstrapTracking(
  trackingService: TrackingService,
  appConfigService: AppConfigService,
  store: Store
): () => Observable<User> {
  return () => {
    if (appConfigService.environment.TRACKING.ENABLED) {
      trackingService.startTracking();
    }

    return store.select(userSelector).pipe(
      take(1),
      filter((user: User) => !!user),
      tap(() => trackingService.dispatch(new AppOpenIdentified()))
    );
  };
}

@NgModule({
  declarations: [],
  imports: [
    AuthModule,
    StoreModule.forRoot({
      user: userReducer.reducer,
      companyConfig: companyConfigReducer.reducer,
      formality: formalityReducer.reducer,
      creationRequest: creationRequestReducer.reducer
    }),
    EffectsModule.forRoot([]),
    CommonModule,
    BrowserAnimationsModule,
    AmplitudeModule,
    TrackingModule.forRoot([InternalTrackingGroup, LegalCreationTrackingGroup]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: true,
      traceLimit: 75
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService, sentryService: SentryService): (() => Promise<void>) =>
        appConfigService.appConfigFactory<void>(() => {
          // Init sentry
          void sentryService.init();
        }),
      deps: [AppConfigService, SentryService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (
        trackingService: TrackingService,
        appConfigService: AppConfigService,
        store: Store<AppStoreState>
      ): (() => void) =>
        appConfigService.appConfigFactory(() => bootstrapTracking(trackingService, appConfigService, store)),
      deps: [TrackingService, AppConfigService, Store],
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    provideBootstrapEffects([UserEffects, FormalityEffects, CreationRequestEffects])
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
