import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiCommonService } from '@bases/services-api.base';
import { ApiAlertError } from '@core/decorators/api-alert-error';
import { Formality, FormalityJson } from '@models/formality.model';

@Injectable({
  providedIn: 'root'
})
export class FormalityApiService extends ApiCommonService<Formality, FormalityJson> {
  resourceUrl = 'api/v1/legal/user_validation/formalities';
  singleRessourceUrl = 'api/v1/legal/user_validation/formality';

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  fromJson(json: FormalityJson): Formality {
    return Formality.fromJson(json);
  }

  toJson(model: Formality): FormalityJson {
    return null;
  }

  @ApiAlertError()
  getFormalities(): Observable<Formality[]> {
    return super.getAll();
  }

  @ApiAlertError()
  getFormality(formalityId: number): Observable<Formality> {
    return super.getById(formalityId, this.singleRessourceUrl);
  }
}
