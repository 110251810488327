import { animate, style, transition, trigger, AnimationTriggerMetadata, query, stagger } from '@angular/animations';

const animationBody = [
  transition('* => *', [
    query(
      ':enter',
      style({
        transform: 'scale3d(0, 0, 1)',
        opacity: 0,
        visibility: 'hidden'
      }),
      { optional: true }
    ),

    query(
      ':enter',
      stagger('25ms', [
        animate(
          '.175s cubic-bezier(0.4,0.0,1,1)',
          style({
            transform: 'scale3d(1, 1, 1)',
            opacity: 1,
            visibility: 'visible'
          })
        )
      ]),
      { optional: true }
    )
  ])
];

export const tiimeListItemAnimations: {
  readonly listWrapper: AnimationTriggerMetadata;
} = {
  listWrapper: trigger('listAnimation', animationBody)
};
