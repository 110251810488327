import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AutocompleteOffDirective } from '@core/directives/autocomplete-off.directive';
import { MaterialModule } from '@modules/shared/material/material.module';
import { setSharedInjector } from '@modules/shared/shared-injector';

import { ErrorPageComponent } from './error-page/error-page.component';

@NgModule({
  declarations: [AutocompleteOffDirective, ErrorPageComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  exports: [CommonModule, MaterialModule, ReactiveFormsModule, AutocompleteOffDirective]
})
export class SharedModule {
  constructor(injector: Injector) {
    setSharedInjector(injector);
  }
}
