import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';

import { TiimeDialogCloseButtonComponent } from './dialog-close-button/dialog-close-button.component';
import { TiimeDialogFooterModule } from './dialog-footer/dialog-footer.module';
import { TiimeButtonModule } from '../button';

@NgModule({
  imports: [MatDialogModule, TiimeButtonModule, TiimeDialogFooterModule],
  exports: [MatDialogModule, TiimeDialogFooterModule, TiimeDialogCloseButtonComponent],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        panelClass: 'tiime-dialog-panel',
        backdropClass: 'tiime-backdrop-dialog',
        hasBackdrop: true,
        closeOnNavigation: true,
        enterAnimationDuration: 0
      }
    }
  ],
  declarations: [TiimeDialogCloseButtonComponent]
})
export class TiimeDialogModule {}
