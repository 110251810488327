import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutoSaveComponent } from './auto-save.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AutoSaveComponent],
  exports: [AutoSaveComponent]
})
export class TiimeAutoSaveModule {}
