import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom } from 'rxjs';

import { AppStoreState } from '@interfaces/app-store-state';

import * as CreationRequestActions from './creation-request-actions';
import { creationRequestSelector } from './creation-request-selector';

@Injectable()
export class CreationRequestEffects {
  constructor(
    private store: Store<AppStoreState>,
    private actions$: Actions
  ) {}

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CreationRequestActions.SELECT),
        withLatestFrom(this.store.select(creationRequestSelector))
      ),
    { dispatch: false }
  );
}
