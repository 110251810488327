import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeSimpleEmptyStateComponent } from './simple-empty-state.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TiimeSimpleEmptyStateComponent],
  exports: [TiimeSimpleEmptyStateComponent]
})
export class TiimeSimpleEmptyStateModule {}
