import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PaginatorComponent } from './paginator.component';
import { TiimeIconModule } from '../icon/index';

@NgModule({
  imports: [CommonModule, TiimeIconModule],
  declarations: [PaginatorComponent],
  exports: [PaginatorComponent]
})
export class TiimePaginatorModule {}
