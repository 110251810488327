import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthCardComponent } from './auth-card.component';

@NgModule({
  declarations: [AuthCardComponent],
  exports: [AuthCardComponent],
  imports: [CommonModule]
})
export class AuthCardModule {}
