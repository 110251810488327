import { createAction, props } from '@ngrx/store';

import { CreationRequest } from '@models/creation-request.model';

export const SELECT = '[CreationRequest] Select';
export const UPDATE = '[CreationRequest] Update';
export const PATCH = '[CreationRequest] Patch';

export const select = createAction(SELECT, props<{ creationRequest: CreationRequest }>());
export const update = createAction(UPDATE, props<{ creationRequest: CreationRequest }>());
export const patch = createAction(PATCH, props<{ creationRequestPartial: Partial<CreationRequest> }>());
