import { Action, createReducer, on } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import { CreationRequest } from '@models/creation-request.model';

import * as CreationRequestActions from './creation-request-actions';

export type CreationRequestState = CreationRequest | null;
export const initialState: CreationRequestState = null;

const creationRequestReducer = createReducer(
  initialState,
  on(CreationRequestActions.select, setCreationRequest),
  on(CreationRequestActions.update, setCreationRequest),
  // a shallow copy is made because we are not copying nested objects
  on(CreationRequestActions.patch, (state, { creationRequestPartial }) => ({
    ...state,
    ...creationRequestPartial
  }))
);

function setCreationRequest(_, { creationRequest }: { creationRequest: CreationRequest }): CreationRequestState {
  return cloneDeep(creationRequest);
}

export function reducer(state: CreationRequestState, action: Action): CreationRequestState {
  return creationRequestReducer(state, action);
}
