import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimePipesModule } from '@manakincubber/tiime-utils';

import { DateListCellComponent } from './date-list-cell.component';
import { DatetimePipeModule } from '../pipes';

@NgModule({
  imports: [CommonModule, TiimePipesModule, DatetimePipeModule],
  declarations: [DateListCellComponent],
  exports: [DateListCellComponent]
})
export class TiimeDateListCellModule {}
