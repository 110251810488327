import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthModule as Auth0Module, AuthClientConfig } from '@auth0/auth0-angular';
import { TiimePipesModule } from '@manakincubber/tiime-utils';

import { AuthCardModule } from '@modules/shared/components/auth-card/auth-card.module';
import { LegalCardHeaderComponent } from '@modules/shared/components/legal-card-header/legal-card-header.component';
import { SharedModule } from '@modules/shared/shared.module';
import { AppConfigService } from '@services/app-config.service';

import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AUTH0_CACHE_LOCATION, AUTH0_REDIRECT_URI, AUTH0_SCOPE } from './auth.constant';
import { SigninComponent } from './signin/signin.component';

@NgModule({
  imports: [
    Auth0Module.forRoot(),
    CommonModule,
    SharedModule,
    AuthCardModule,
    HttpClientModule,
    TiimePipesModule,
    LegalCardHeaderComponent,
    RouterModule
  ],
  declarations: [AuthCallbackComponent, SigninComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService, authClientConfig: AuthClientConfig): (() => Promise<void>) =>
        appConfigService.appConfigFactory<void>(() => {
          authClientConfig.set({
            domain: appConfigService.environment.AUTH0_DOMAIN,
            clientId: appConfigService.environment.AUTH0_CLIENT_ID,
            cacheLocation: AUTH0_CACHE_LOCATION,
            authorizationParams: {
              scope: AUTH0_SCOPE,
              audience: appConfigService.environment.AUTH0_AUDIENCE,
              redirect_uri: AUTH0_REDIRECT_URI
            },
            httpInterceptor: {
              allowedList: [
                {
                  uri: `${appConfigService.environment.API_URL}/*`
                }
              ]
            }
          });
        }),
      deps: [AppConfigService, AuthClientConfig],
      multi: true
    }
  ]
})
export class AuthModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error(`AuthModule is already loaded. It should only be imported in Core Module only.`);
    }
  }
}
