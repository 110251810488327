import { inject } from '@angular/core';

import { createTracker, Events, withName } from '@manakincubber/tiime-tracking';
import { Store } from '@ngrx/store';
import { first, switchMap, tap } from 'rxjs/operators';

import { userSelector } from '@core/store/user';
import { User } from '@models/user.model';

import { AmplitudeEvents, APP_OPEN_IDENTIFIED, USER_LOG_OUT } from '../events';
import { prefixUserId } from '../id-prefix';
import { AMPLI } from '../tokens';

export class InternalTrackingGroup {
  private readonly events$ = inject<Events<AmplitudeEvents>>(Events);
  private readonly ampli = inject(AMPLI);
  private readonly store = inject(Store);

  handleUserIdentification$ = createTracker(
    this.events$.pipe(
      withName(APP_OPEN_IDENTIFIED),
      switchMap(() => this.store.select(userSelector).pipe(first(user => !!user))),
      tap((user: User) => {
        const amplitudeUserId = prefixUserId(user.id);
        this.ampli.identify(amplitudeUserId);
      })
    )
  );

  handleUserLogOut$ = createTracker(
    this.events$.pipe(
      withName(USER_LOG_OUT),
      tap(() => {
        this.ampli.client.reset();
      })
    )
  );
}
