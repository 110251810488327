export interface CompanyConfigJson {
  legal_business_price: boolean;
  show_legal_tracking: boolean;
}

export class CompanyConfig {
  constructor(
    public legalBusinessPrice: boolean,
    public showLegalTracking: boolean
  ) {}

  static fromJson(json: CompanyConfigJson): CompanyConfig {
    return new CompanyConfig(json.legal_business_price, json.show_legal_tracking);
  }
}
