import { Action, createReducer, on } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import { Formality } from '@models/formality.model';

import * as FormalityActions from './formality-actions';

export type FormalityState = Formality | null;
export const initialState: FormalityState = null;

const formalityReducer = createReducer(
  initialState,
  on(FormalityActions.select, setFormality),
  on(FormalityActions.update, setFormality),
  // a shallow copy is made because we are not copying nested objects
  on(FormalityActions.patch, (state, { formalityPartial }) => ({
    ...state,
    ...formalityPartial
  })),
  on(FormalityActions.remove, () => null)
);

function setFormality(_, { formality }: { formality: Formality }): FormalityState {
  return cloneDeep(formality);
}

export function reducer(state: FormalityState, action: Action): FormalityState {
  return formalityReducer(state, action);
}
