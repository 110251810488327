export interface UserJson {
  id: number;
  firstname?: string;
  lastname?: string;
  phone?: string;
  email?: string;
  acronym: string;
  color: string;
}

export class User {
  constructor(
    public id?: number,
    public firstname?: string,
    public lastname?: string,
    public phone?: string,
    public email?: string,
    public acronym?: string,
    public color?: string
  ) {}

  static fromJson(json: UserJson): User {
    return new User(json.id, json.firstname, json.lastname, json.phone, json.email, json.acronym, json.color);
  }

  static toJson(user: User): UserJson {
    return {
      id: user.id,
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone,
      email: user.email,
      acronym: user.acronym,
      color: user.color
    };
  }
}
