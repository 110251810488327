import { Types } from '@amplitude/analytics-browser';

import { Environment } from '@interfaces/environment';

export const environment: Environment = {
  production: true,
  ENV_NAME: 'production',
  AUTH0_DOMAIN: 'auth0.tiime.fr',
  AUTH0_CLIENT_ID: 'JjXiWSzBBlINw1kajChO8LJsP0ELl4VH',
  AUTH0_REALM: 'Chronos-prod-db',
  AUTH0_AUDIENCE: 'https://chronos/',
  AUTH0_WHITE_DOMAINS: ['chronos-api.tiime-apps.com'],
  API_URL: 'https://chronos-api.tiime-apps.com',
  APPS_URL: 'https://apps.tiime.fr',
  SENTRY_DSN: 'https://9b4f867da8f74db0be5daabd03a0150d@sentry.tiime.tech/2',
  TRACKING: {
    ENABLED: true,
    AMPLITUDE_OPTIONS: {
      environment: 'production',
      configuration: { useBatch: false, logLevel: Types.LogLevel.Warn }
    }
  }
};
