import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom } from 'rxjs';

import { AppStoreState } from '@interfaces/app-store-state';

import * as FormalityActions from './formality-actions';
import { formalitySelector } from './formality-selector';

@Injectable()
export class FormalityEffects {
  constructor(
    private store: Store<AppStoreState>,
    private actions$: Actions
  ) {}

  updateAction$ = createEffect(
    () => this.actions$.pipe(ofType(FormalityActions.SELECT), withLatestFrom(this.store.select(formalitySelector))),
    { dispatch: false }
  );
}
