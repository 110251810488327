import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, take } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { QUERY_PARAMS } from '@constants/query-params.constant';
import { QUERY_PARAMS_MAP } from '@core/tokens/query-params-map.token';

import { AuthRedirectLoginOptions, AuthService } from '../auth.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninComponent implements OnInit {
  constructor(
    private authService: AuthService,
    @Inject(QUERY_PARAMS_MAP) private queryParamsMap$: Observable<ParamMap>
  ) {}

  ngOnInit(): void {
    this.login();
  }

  private login(): void {
    this.getLoginHintQueryParams()
      .pipe(
        switchMap((loginHintParam: string) => {
          let options: AuthRedirectLoginOptions = undefined;
          if (loginHintParam) {
            options = {
              authorizationParams: {
                login_hint: loginHintParam
              }
            };
          }

          return this.authService.login(options);
        })
      )
      .subscribe();
  }

  private getLoginHintQueryParams(): Observable<string> {
    return this.queryParamsMap$.pipe(
      take(1),
      map((queryParams: ParamMap) => queryParams.get(QUERY_PARAMS.loginHint))
    );
  }
}
