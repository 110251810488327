import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { SnackbarType } from './snackbar-config';

export interface SnackbarDataType {
  message: string;
  type: SnackbarType;
  actionButtonText?: string;
}

@Component({
  selector: 'tiime-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent {
  data: SnackbarDataType;
  isSuccessSnackbar: boolean;

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) data: SnackbarDataType
  ) {
    this.data = data;
    this.isSuccessSnackbar = data.type === SnackbarType.success;
  }

  close(): void {
    this.snackBarRef.dismissWithAction();
  }
}
