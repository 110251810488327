import { Injectable } from '@angular/core';

import { TrackingService } from '@manakincubber/tiime-tracking';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';

import { AppOpenIdentified } from '@core/amplitude/events';
import { SentryService } from '@services/sentry.service';

import * as UserActions from './user-actions';
import { userSelector } from './user-selector';

@Injectable()
export class UserEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private sentryService: SentryService,
    private trackingService: TrackingService
  ) {}

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.UPDATE),
        withLatestFrom(this.store.select(userSelector)),
        tap(([, user]) => this.sentryService.setUser(user)),
        tap(() => this.trackingService.dispatch(new AppOpenIdentified()))
      ),
    { dispatch: false }
  );
}
