import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MomentDateModule } from '@angular/material-moment-adapter';

import {
  TiimeSlideToggleModule,
  TiimeCheckboxModule,
  TiimeMenuModule,
  TiimeRadioModule,
  TiimeButtonModule,
  TiimePaginatorModule,
  TiimeTableModule,
  TiimeSearchBarModule,
  TiimeIconModule,
  TiimeSnackbarModule,
  TiimeTagModule,
  TiimeActionBarModule,
  TiimeCardModule,
  TiimeTooltipModule,
  TiimeAutoSaveModule,
  TiimeDialogModule,
  TiimeInputContainerModule,
  TiimeGooglePlacesModule,
  TiimeEmptyStateModule,
  TiimeListModule,
  TiimeTwocolorsIconModule,
  TIIME_RIPPLE_GLOBAL_OPTIONS,
  TIIME_DATE_FORMATS,
  TiimeFileModule,
  TiimeDatepickerModule,
  TiimeProgressBarModule,
  TiimeLoaderModule,
  TiimeOverlayModule,
  TiimeDateListCellModule,
  TiimeSimpleEmptyStateModule,
  TiimeSidenavLayoutModule,
  TiimeNavbarModule,
  TiimeMultilineEllipsisModule,
  TiimeCarouselModule,
  TiimeSortModule,
  TiimeAutoFocusModule,
  TiimeLetModule
} from 'tiime-components';

@NgModule({
  imports: [MomentDateModule],
  providers: [
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: TIIME_RIPPLE_GLOBAL_OPTIONS
    },
    { provide: MAT_DATE_FORMATS, useValue: TIIME_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } }
  ],
  exports: [
    TiimeSlideToggleModule,
    TiimeCheckboxModule,
    TiimeMenuModule,
    TiimeRadioModule,
    TiimeButtonModule,
    TiimePaginatorModule,
    TiimeTableModule,
    TiimeSearchBarModule,
    TiimeIconModule,
    TiimeSnackbarModule,
    TiimeTagModule,
    TiimeActionBarModule,
    TiimeCardModule,
    TiimeTooltipModule,
    TiimeAutoSaveModule,
    TiimeDialogModule,
    TiimeInputContainerModule,
    TiimeGooglePlacesModule,
    TiimeEmptyStateModule,
    TiimeListModule,
    TiimeTwocolorsIconModule,
    TiimeFileModule,
    TiimeDatepickerModule,
    TiimeProgressBarModule,
    TiimeLoaderModule,
    TiimeDateListCellModule,
    TiimeSimpleEmptyStateModule,
    MatButtonToggleModule,
    MatInputModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSelectModule,
    TiimeOverlayModule,
    TiimeSidenavLayoutModule,
    TiimeNavbarModule,
    TiimeMultilineEllipsisModule,
    TiimeCarouselModule,
    TiimeSortModule,
    OverlayModule,
    DragDropModule,
    TiimeAutoFocusModule,
    TiimeLetModule
  ]
})
export class MaterialModule {}
