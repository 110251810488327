<div
  class="input-container-wrapper"
  [class.input-container-wrapper--with-label]="!!label"
>
  <span *ngIf="suffix" class="suffix">{{ suffix }}</span>
  <ng-content></ng-content>
  <label *ngIf="label" [attr.for]="contentId">{{ label }}</label>
  <span
    *ngIf="errorMessage"
    class="error-label"
    data-cy="input-container-error"
  >
    {{ errorMessage }}
  </span>
</div>
