import { Inject, Injectable } from '@angular/core';

import { Observable, Subject, from, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { VERSION } from '@core/tokens/version.token';
import { environment } from '@env';
import { Environment } from '@interfaces/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  environment: Environment;
  version: string;

  private readonly isConfigReady$ = new Subject();

  constructor(@Inject(VERSION) version: string) {
    this.version = version;
  }

  appConfigFactory<T>(fn: (...args: unknown[]) => unknown): () => Promise<T> {
    return (): Promise<T> =>
      new Promise((resolve, reject) =>
        this.isConfigReady$
          .pipe(
            filter(isConfigReady => !!isConfigReady),
            tap(() => fn())
          )
          .subscribe(resolve, reject)
      );
  }

  loadEnvironment(): Observable<Environment> {
    const loadEnvironmentObs = environment.dynamic
      ? from(fetch('assets/config/app-config.json').then(response => response.json()))
      : of(environment);

    return loadEnvironmentObs.pipe(
      tap(loadedEnvironment => {
        this.environment = loadedEnvironment;
        this.isConfigReady$.next(true);
      })
    );
  }
}
