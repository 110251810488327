<div class="action-bar-wrapper">
  <div class="action-bar" #actionBar>
    <div class="left">
      <ng-container *ngIf="backButtonConfig">
        <a
          *ngIf="backButtonConfig.link !== CustomAction.OUTPUT"
          class="back-button"
          tiimeTooltip
          tooltipPosition="bottom"
          [routerLink]="backButtonConfig.link"
          [queryParamsHandling]="backButtonConfig.queryParamsHandling"
          [content]="backButtonLinkTooltip"
        >
          <mat-icon inline="true" fontIcon="icon-ic-back"></mat-icon>
        </a>
        <button
          *ngIf="backButtonConfig.link === CustomAction.OUTPUT"
          class="back-button"
          type="button"
          tiime-button
          xsmall
          flat
          tiimeTooltip
          tooltipPosition="bottom"
          [content]="backButtonLinkTooltip"
          (click)="backButtonCustomAction.emit()"
        >
          <mat-icon inline="true" fontIcon="icon-ic-back"></mat-icon>
        </button>
      </ng-container>
      <ng-template #backButtonLinkTooltip>
        <div class="back-button-link-tooltip">
          {{ backButtonConfig.title }}
        </div>
      </ng-template>
      <div class="feature" [class.with-back-button]="backButtonConfig">
        <ng-content select="[action-bar-searchbar]"></ng-content>
        <ng-content select="[action-bar-title]"></ng-content>
      </div>
      <mat-progress-spinner
        *ngIf="loading"
        diameter="25"
        color="primary"
        mode="indeterminate"
      ></mat-progress-spinner>
      <tiime-auto-save [autoSaveData]="autoSaveData$ | async"></tiime-auto-save>
    </div>
    <ng-content select="[action-bar-content]"></ng-content>
    <div class="right">
      <ng-content select="[action-bar-actions]"></ng-content>
      <ng-content select="[action-bar-more-actions]"></ng-content>
    </div>
  </div>
</div>
