
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SharedModule } from '@modules/shared/shared.module';

@Component({
  selector: 'app-legal-card-header',
  templateUrl: './legal-card-header.component.html',
  styleUrls: ['./legal-card-header.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalCardHeaderComponent {
  @Input() icon = '';
  @Input() title = '';
  @Input() subtitle = '';
  @Input() maxSize = 45;
}
