import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ApiCommonService } from '@bases/services-api.base';
import { ApiAlertError } from '@core/decorators/api-alert-error';
import { User, UserJson } from '@models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends ApiCommonService<User, UserJson> {
  resourceUrl = 'api/v1/users/me';

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  fromJson(json: UserJson): User {
    return User.fromJson(json);
  }

  toJson(model: User): UserJson {
    return null;
  }

  @ApiAlertError()
  getUser(): Observable<User> {
    return this.httpClient.get<UserJson>(this.resourceUrl).pipe(map((userJson: UserJson) => this.fromJson(userJson)));
  }
}
