import { inject, InjectionToken } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export const QUERY_PARAMS_MAP = new InjectionToken<Observable<ParamMap>>(
  'Observable of activated route query params map',
  {
    factory: () => {
      const activatedRoute = inject(ActivatedRoute);
      return activatedRoute.queryParamMap.pipe(take(1));
    }
  }
);
