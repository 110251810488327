import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
  ScrollDispatcher
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TOOLTIP_CONNECTION_POSITION_PAIR, TooltipConnectionPositionPair } from './tooltip-connection-position-pair';
import { TooltipPosition } from './tooltip-position';
import { TooltipComponent } from './tooltip.component';
import { HasAttributesBase } from '../core';

@Directive({
  selector: '[tiimeTooltip]',
  exportAs: 'tiime-tooltip'
})
export class TooltipDirective extends HasAttributesBase implements OnDestroy {
  @Input() content: TemplateRef<any>;
  @Input() tooltipShowDelay = 0;
  @Input() tooltipHideDelay = 0;

  @Input()
  set hideWithoutEllipsis(hideWithoutEllipsis: BooleanInput) {
    this._hideWithoutEllipsis = coerceBooleanProperty(hideWithoutEllipsis);
  }
  get hidewithoutEllipsis(): boolean {
    return this._hideWithoutEllipsis;
  }
  private _hideWithoutEllipsis = false;

  @Input('tooltipPosition')
  get position(): TooltipPosition {
    return this.tooltipPosition;
  }
  set position(value: TooltipPosition) {
    if (value !== this.tooltipPosition) {
      this.tooltipPosition = value;

      if (this.overlayRef) {
        const positionStrategy = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
        this.setPosition(positionStrategy);
        this.overlayRef.updatePosition();
      }
    }
  }

  @Input('tooltipInvertedPosition')
  get invertedPosition(): TooltipPosition {
    return this.tooltipInvertedPosition;
  }
  set invertedPosition(value: TooltipPosition) {
    if (value !== this.tooltipInvertedPosition) {
      this.tooltipInvertedPosition = value;
    }
  }

  @Input('tooltipDisabled')
  get disabled(): boolean {
    return this.tooltipDisabled;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set disabled(value) {
    this.tooltipDisabled = value;

    if (this.tooltipDisabled) {
      this.hide(0);
    }
  }

  @Input('tooltipClass')
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
  get class() {
    return this.tooltipClass;
  }
  set class(value: string | string[] | Set<string> | { [key: string]: any }) {
    this.tooltipClass = value;
    if (this.tooltipInstance) {
      this.setTooltipClass(this.tooltipClass);
    }
  }

  tooltipInstance: TooltipComponent | null;

  private overlayRef: OverlayRef;
  private tooltipPortal: ComponentPortal<TooltipComponent>;
  private tooltipPosition: TooltipPosition = 'right';
  private tooltipInvertedPosition: TooltipPosition;
  private tooltipDisabled = false;
  private tooltipClass: string | string[] | Set<string> | { [key: string]: any };

  /** Emits when the component is destroyed. */
  private readonly destroyed = new Subject<void>();

  private get hasEllipsis(): boolean {
    return (
      this.elementRef.nativeElement.offsetWidth < this.elementRef.nativeElement.scrollWidth ||
      this.elementRef.nativeElement.offsetHeight < this.elementRef.nativeElement.scrollHeight
    );
  }

  constructor(
    elementRef: ElementRef,
    private overlay: Overlay,
    private scrollDispatcher: ScrollDispatcher,
    private viewContainerRef: ViewContainerRef
  ) {
    super(elementRef);
  }

  @HostListener('mouseenter')
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
  onMouseEnter() {
    if (
      (this._hasHostAttributes('hideWithoutEllipsis') && !this.hasEllipsis && !this.hideWithoutEllipsis) ||
      this.tooltipDisabled
    ) {
      return;
    }
    this.show();
  }
  @HostListener('mouseleave')
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
  onMouseLeave() {
    this.hide();
  }

  /**
   * Dispose the tooltip when destroyed.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    this.destroyed.next();
    this.destroyed.complete();
  }

  show(delay: number = this.tooltipShowDelay): void {
    const overlayRef = this.createOverlay();
    this.detach();
    // Create ComponentPortal that can be attached to a PortalHost
    this.tooltipPortal = this.tooltipPortal || new ComponentPortal(TooltipComponent, this.viewContainerRef);

    // Attach ComponentPortal to PortalHost
    this.tooltipInstance = overlayRef.attach(this.tooltipPortal).instance;
    this.tooltipInstance
      .afterHidden()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => this.detach());
    this.updateTooltipContent();
    const positionStrategy = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
    this.setPosition(positionStrategy);
    this.setTooltipClass(this.tooltipClass);

    this.tooltipInstance.show(delay);
  }

  hide(delay: number = this.tooltipHideDelay): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.hide(delay);
    }
  }

  private createOverlay(): OverlayRef {
    if (this.overlayRef) {
      return this.overlayRef;
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withTransformOriginOn('.tooltip-container')
      .withFlexibleDimensions(false)
      .withViewportMargin(10);

    this.setPosition(positionStrategy);

    const scrollableAncestors = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);

    positionStrategy.withScrollableContainers(scrollableAncestors);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: false,
      panelClass: 'tooltip-overlay',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy
    });

    this.overlayRef = this.overlay.create(overlayConfig);

    this.overlayRef
      .detachments()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => this.detach());
    return this.overlayRef;
  }

  private updateTooltipContent(): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.content = this.content;
      this.tooltipInstance.markForCheck();
    }
  }

  /** Detaches the currently-attached tooltip. */
  private detach(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
    if (this.tooltipInstance) {
      this.tooltipInstance = null;
    }
  }

  /** Set the position of the current tooltip. */
  private setPosition(positionStrategy: FlexibleConnectedPositionStrategy): void {
    const mainPosition = this.getTooltipConnectionPositionPair(this.position);
    let invertedPosition: TooltipPosition;
    if (this.tooltipInvertedPosition) {
      invertedPosition = this.invertedPosition;
    } else {
      switch (this.position) {
        case 'right':
          invertedPosition = 'left';
          break;
        case 'left':
          invertedPosition = 'right';
          break;
        case 'bottom':
          invertedPosition = 'top';
          break;
        case 'top':
          invertedPosition = 'bottom';
          break;
        case 'above':
          invertedPosition = 'below';
          break;
        case 'below':
          invertedPosition = 'above';
          break;
        case 'below-left':
          invertedPosition = 'above-left';
          break;
        case 'above-left':
          invertedPosition = 'below-left';
          break;
        case 'below-right':
          invertedPosition = 'above-right';
          break;
        case 'above-right':
          invertedPosition = 'below-right';
          break;
        case 'above-start':
          invertedPosition = 'below-start';
          break;
        case 'below-start':
          invertedPosition = 'above-start';
          break;
      }
    }
    const fallbackPosition = this.getTooltipConnectionPositionPair(invertedPosition);
    positionStrategy.withPositions([mainPosition, fallbackPosition]);
  }

  private getTooltipConnectionPositionPair(position: TooltipPosition): TooltipConnectionPositionPair {
    const tooltipConnectionPositionPair = TOOLTIP_CONNECTION_POSITION_PAIR[position];
    if (!tooltipConnectionPositionPair) {
      throw Error(`Tooltip position "${position}" is invalid.`);
    }
    return tooltipConnectionPositionPair;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
  private setTooltipClass(tooltipClass: string | string[] | Set<string> | { [key: string]: any }) {
    if (this.tooltipInstance) {
      this.tooltipInstance.tooltipClass = tooltipClass;
      this.tooltipInstance.markForCheck();
    }
  }
}
