import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * API Common Service.
 */
@Injectable({ providedIn: 'root' })
export abstract class ApiCommonService<T, Json = unknown> {
  abstract resourceUrl: string;
  abstract fromJson(json: Json): T;
  abstract toJson(model: T): unknown;

  constructor(protected http: HttpClient) {}

  /**
   * Get entity by identifier.
   *
   * @param id the entity identifier.
   * @returns the funded entity.
   */
  protected getById(id: number, url: string = this.resourceUrl): Observable<T> {
    const currentUrl = `${url}/${id}`;
    return this.http.get(currentUrl).pipe(map((json: Json) => this.fromJson(json)));
  }

  /**
   * Get all entities.
   *
   * @param options the http call options. It can include http params and http headers.
   * @param url the endpoint url if it is different than default resource url.
   * @returns the entities.
   */
  protected getAll(
    options?: { params?: HttpParams; headers?: HttpHeaders },
    url: string = this.resourceUrl
  ): Observable<T[]> {
    return this.http
      .get(url, options || {})
      .pipe(map((jsonArray: Json[]) => jsonArray.map((json: Json) => this.fromJson(json))));
  }

  /**
   * Search entities.
   *
   * @param params the search parameters
   * @param url the endpoint url if it is different than default resource url.
   * @returns the found entities.
   */
  protected search(params: HttpParams, url: string = this.resourceUrl): Observable<T[]> {
    return this.http.get(url, { params }).pipe(map((jsonArray: any) => jsonArray.map(json => this.fromJson(json))));
  }

  /**
   * Create an entity.
   *
   * @param data the entity data.
   * @param url the endpoint url if it is different than default resource url.
   * @returns the created entity.
   */
  protected create(data: T, url: string = this.resourceUrl): Observable<T> {
    const body = data ? this.toJson(data) : null;
    return this.http.post(url, body).pipe(map((json: any) => this.fromJson(json)));
  }

  /**
   * Create multiple entities.
   *
   * @param data the entities data.
   * @param url the endpoint url if it is different than default resource url.
   * @returns the created entity.
   */
  protected createMultiple(data: T[], url: string = this.resourceUrl): Observable<T[]> {
    const body = data.map((model: T) => this.toJson(model));
    return this.http.post(url, body).pipe(map((jsonArray: any) => jsonArray.map(json => this.fromJson(json))));
  }

  /**
   * Update an entity.
   *
   * @param id the entity identifier to update.
   * @param data the entity data.
   * @returns the updated entity.
   */
  protected update(id: number, data: T, url: string = this.resourceUrl): Observable<T> {
    const computedUrl = `${url}/${id}`;
    return this.http.patch(computedUrl, this.toJson(data)).pipe(map((json: any) => this.fromJson(json)));
  }

  /**
   * Update partially an entity.
   *
   * @param id the entity identifier to update.
   * @param data the partial entity data.
   * @returns the updated entity.
   */
  protected updatePartial(id: number, data: Partial<T>): Observable<T> {
    const url = `${this.resourceUrl}/${id}`;
    return this.http.patch(url, data).pipe(map((json: any) => this.fromJson(json)));
  }

  /**
   * Update multiple entities.
   *
   * @param method the method to use
   * @param data the entities data.
   * @param url the endpoint url if it is different than default resource url.
   * @returns the updated entities.
   */
  protected updateMultiple(
    data: T[],
    url: string = this.resourceUrl,
    method: 'POST' | 'PUT' | 'PATCH' = 'PUT'
  ): Observable<T[]> {
    const body = data.map((model: T) => this.toJson(model));
    return this.http
      .request(method, url, { body })
      .pipe(map((jsonArray: any) => jsonArray.map((json: any) => this.fromJson(json))));
  }

  /**
   * Delete an entity.
   *
   * @param id the entity identifier to delete.
   */
  protected delete(id: number, url: string = this.resourceUrl): Observable<unknown> {
    const computedUrl = `${url}/${id}`;
    return this.http.delete(computedUrl).pipe(map((json: any) => this.fromJson(json)));
  }
}
