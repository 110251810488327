<div class="snackbar-content" data-cy="snackbar">
  <div class="message">
    <div *ngIf="isSuccessSnackbar; then successIcon; else failedIcon"></div>
    <ng-template #successIcon>
      <mat-icon
        class="success-icon"
        fontIcon="icon-check-1c-r-b-24-fontastic"
        inline="true"
      ></mat-icon>
    </ng-template>
    <ng-template #failedIcon>
      <mat-icon
        class="failed-icon"
        fontIcon="icon-alerte-input"
        inline="true"
      ></mat-icon>
    </ng-template>
    <span data-cy="snackbar-message">{{ data.message }}</span>
  </div>
  <button
    *ngIf="data.actionButtonText"
    class="actions"
    xxsmall
    tiime-button
    neutral
    inverted
    light
    (click)="close()"
  >
    {{ data.actionButtonText }}
  </button>
  <div *ngIf="!data.actionButtonText" class="actions" (click)="close()">OK</div>
</div>
