import { formatDate } from '@angular/common';
import { Inject, InjectionToken, LOCALE_ID, Optional, Pipe, PipeTransform } from '@angular/core';

export const DATE_PIPE_DEFAULT_OPTIONS = new InjectionToken<string>('DATE_PIPE_DEFAULT_OPTIONS');

@Pipe({ name: 'tiimeDatetime', pure: true })
export class DatetimePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(DATE_PIPE_DEFAULT_OPTIONS)
    @Optional()
    private defaultTimezone?: string | null
  ) {}

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    value: Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null;
  transform(
    value: Date | string | number | null | undefined,
    format = 'mediumDate',
    timezone?: string,
    locale?: string
  ): string | null {
    if (value == null || value === '' || value !== value) return null;
    const safariCompatibleValue = value.toString().replace(' ', 'T');
    try {
      return formatDate(
        safariCompatibleValue,
        format,
        locale || this.locale,
        timezone ?? this.defaultTimezone ?? undefined
      );
    } catch (error) {
      throw Error((error as Error).message);
    }
  }
}
