import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'tiime-progress-spinner-loader',
  templateUrl: './progress-spinner-loader.component.html',
  styleUrls: ['./progress-spinner-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressSpinnerLoaderComponent {
  @Input() color: ThemePalette | 'white';
  @Input() mode: ProgressSpinnerMode;
  @Input() value: number;

  @Input()
  set diameter(diameter: NumberInput) {
    this._diameter = coerceNumberProperty(diameter);
  }
  get diameter(): number {
    return this._diameter;
  }
  private _diameter: number;

  get colorPalette(): ThemePalette | undefined {
    if (this.color === 'white') {
      return undefined;
    }
    return this.color;
  }

  get containerSize(): Record<string, number> {
    return { 'width.px': this.diameter, 'height.px': this.diameter };
  }
}
