import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { formalitySelector } from '@core/store/formality';
import { userSelector } from '@core/store/user';
import { AppStoreState } from '@interfaces/app-store-state';
import { Environment } from '@interfaces/environment';
import { Formality } from '@models/formality.model';
import { User } from '@models/user.model';
import { AppRoute } from '@navigation/app-route.enum';
import { AppConfigService } from '@services/app-config.service';
import { NavigationService } from '@services/navigation.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  user: User;
  formality: Formality;

  get env(): Environment {
    return this.appConfigService.environment;
  }

  get version(): string {
    return this.appConfigService.version;
  }

  constructor(
    private navigationService: NavigationService,
    private store: Store<AppStoreState>,
    private appConfigService: AppConfigService
  ) {
    this.initUser();
    this.observeCurrentFormality();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('api/')) {
      let url = this.replaceApi(request.url);
      if (url.includes('{userId}')) {
        url = this.replaceUserId(url);
      }
      if (url.includes('{formalityId}')) {
        url = this.replaceFormalityId(url);
      }
      if (url.includes('{companyCreationId}')) {
        url = this.replaceCompanyCreationId(url);
      }
      if (url.includes('{companyId}')) {
        url = this.replaceCompanyId(url);
      }

      request = request.clone({
        url,
        setHeaders: {
          'tiime-app': 'tiime-legal-web',
          'tiime-app-version': this.version
        }
      });
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
          void this.navigationService.navigate([AppRoute.error]);
        }

        return throwError(error);
      })
    );
  }

  private initUser(): void {
    this.store
      .pipe(
        select(userSelector),
        tap((user: User) => (this.user = user))
      )
      .subscribe();
  }

  private replaceUserId(url: string): string {
    return url.replace('{userId}', String(this.user.id));
  }

  private replaceFormalityId(url: string): string {
    return url.replace('{formalityId}', String(this.formality.id));
  }

  private replaceCompanyCreationId(url: string): string {
    return url.replace('{companyCreationId}', String(this.formality.companyCreation.id));
  }

  private replaceCompanyId(url: string): string {
    return url.replace('{companyId}', String(this.formality.companyCreation.company.id));
  }

  private observeCurrentFormality(): void {
    this.store
      .pipe(
        select(formalitySelector),
        tap((formality: Formality) => (this.formality = formality))
      )
      .subscribe();
  }

  private replaceApi(url: string): string {
    return `${this.env.API_URL}/${url.replace('api/', '')}`;
  }
}
