export enum AppRoute {
  empty = '',
  auth = 'auth',
  identification = 'identification',
  signin = 'signin',
  passwordCreation = 'password-creation',
  otp = 'otp',
  forgottenPassword = 'forgotten-password',
  authCallback = 'auth-callback',
  formalities = 'formalities',
  error = 'error',
  creation = 'creation',
  cgu = 'cgu'
}
