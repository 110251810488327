import { ApplicationRef, enableProdMode, isDevMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';

import { environment } from '@env';

import { AppModule } from './app/app.module';

function enableDebugTools(moduleRef: NgModuleRef<AppModule>): void {
  import('@angular/platform-browser').then(platformBrowser => {
    const applicationRef = moduleRef.injector.get(ApplicationRef);
    const componentRef = applicationRef.components[0];
    platformBrowser.enableDebugTools(componentRef);
  });
}

if (environment.production) {
  enableProdMode();
}

const activeTransaction = Sentry.getActiveTransaction();
const bootstrapSpan =
  activeTransaction &&
  activeTransaction.startChild({
    description: 'platform-browser-dynamic',
    op: 'ui.angular.bootstrap'
  });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef: NgModuleRef<AppModule>) => {
    if (isDevMode()) {
      enableDebugTools(moduleRef);
    }
  })
  .catch(err => console.log(err))
  .finally(() => {
    if (bootstrapSpan) {
      bootstrapSpan.finish();
    }
  });
