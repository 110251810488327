import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { map, Observable } from 'rxjs';

import { Cgu } from '@core/models/cgu.model';
import { CguApiService } from '@core/services/api/cgu-api.service';

@Injectable({
  providedIn: 'root'
})
export class CGUGuard {
  constructor(
    private router: Router,
    private cguApiService: CguApiService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.isCGUAccepted();
  }

  private isCGUAccepted(): Observable<boolean | UrlTree> {
    return this.cguApiService.unsignedDocumentAgreements().pipe(
      map((unsignedDocuments: Cgu[]) => {
        if (unsignedDocuments.length > 0) {
          return this.router.createUrlTree(['cgu']);
        }

        return true;
      })
    );
  }
}
