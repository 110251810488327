export enum StepStatusEnum {
  passwordCreation = 'password_creation',
  companyCheck = 'company_check',
  headOfficeCheck = 'head_office_check',
  activityCheck = 'activity_check',
  capitalCheck = 'capital_check',
  responsability = 'responsability',
  fiscalOptionsCheck = 'fiscal_options_check',
  physicalPersonPersonalInfoCheck = 'physical_person_personal_info_check',
  physicalPersonContactInfoCheck = 'physical_person_contact_info_check',
  physicalPersonEmployedStatusInfoCheck = 'physical_person_employed_status_info_check',
  physicalPersonSpouseInfoCheck = 'physical_person_spouse_info_check',
  physicalPersonParentsInfoCheck = 'physical_person_parents_info_check',
  allUserInfoCheck = 'all_user_info_check',
  documentCheck = 'document_check',
  termsAndConditionsValidation = 'terms_and_conditions_validation',
  signatureCollect = 'signature_collect'
}
