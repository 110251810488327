import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { RedirectIfResourceNotAvailable } from '@core/decorators/redirect-if-resource-not-available';
import { AppStoreState } from '@interfaces/app-store-state';
import { Formality } from '@models/formality.model';
import { FormalityApiService } from '@services/api/formality-api.service';

import * as FormalityActions from '../store/formality/formality-actions';
import { formalitySelector } from '../store/formality/formality-selector';

@Injectable({
  providedIn: 'root'
})
export class FormalityGuard {
  constructor(
    private store: Store<AppStoreState>,
    private formalityApiService: FormalityApiService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.hasFormality(Number(route.params.id));
  }

  @RedirectIfResourceNotAvailable()
  private getFormality(formalityId: number): Observable<Formality> {
    return this.formalityApiService.getFormality(formalityId);
  }

  private hasFormalityInStore(formalityId: number): Observable<boolean> {
    return this.store.pipe(
      select(formalitySelector),
      map((formality: Formality) => formality && formality.id === formalityId),
      take(1)
    );
  }

  private hasFormalityInApi(formalityId: number): Observable<boolean> {
    return this.getFormality(formalityId).pipe(
      tap((formality: Formality) => this.store.dispatch(FormalityActions.select({ formality }))),
      catchError(() => of(false)),
      switchMap(() => this.hasFormalityInStore(formalityId))
    );
  }

  private hasFormality(routeFormalityId: number): Observable<boolean> {
    return this.hasFormalityInStore(routeFormalityId).pipe(
      switchMap((inStore: boolean) => (inStore ? of(inStore) : this.hasFormalityInApi(routeFormalityId)))
    );
  }
}
