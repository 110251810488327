import { APP_INITIALIZER, NgModule, inject } from '@angular/core';

import { Types } from '@amplitude/analytics-browser';

import { AmplitudeOptions } from '@interfaces/environment';
import { AppConfigService } from '@services/app-config.service';

import { GroupsPatchPlugin } from './groups-patch-plugin';
import { AMPLI } from './tokens';
import { Ampli } from './wrapper';
import { WINDOW } from '../tokens/window.token';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (): (() => Promise<void>) => {
        const appConfigService = inject(AppConfigService);
        const ampli = inject(AMPLI);
        const plugins = inject(GroupsPatchPlugin);
        const windowRef = inject(WINDOW);

        return appConfigService.appConfigFactory<void>(() => {
          if (!appConfigService.environment.TRACKING.ENABLED) {
            return;
          }

          initializeAmplitude(
            ampli,
            plugins,
            windowRef.location.hostname,
            appConfigService.environment.TRACKING.AMPLITUDE_OPTIONS,
            appConfigService.version
          );
        });
      },
      multi: true
    }
  ]
})
export class AmplitudeModule {}

export function initializeAmplitude(
  ampli: Ampli,
  groupsPatchPlugin: GroupsPatchPlugin,
  hostname: string,
  amplitudeOptions: AmplitudeOptions,
  version: string
): void {
  const clientConfig: Types.BrowserOptions = {
    appVersion: version,
    serverZone: 'EU',
    defaultTracking: {
      attribution: { excludeReferrers: [hostname] },
      fileDownloads: false,
      pageViews: false,
      sessions: false,
      formInteractions: false
    },
    ...amplitudeOptions.configuration
  };

  ampli.load({
    environment: amplitudeOptions.environment,
    client: { configuration: clientConfig }
  });

  ampli.client.add(groupsPatchPlugin);
}
