import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SnackbarComponent } from './snackbar.component';
import { TiimeButtonModule } from '../button';
import { TiimeIconModule } from '../icon/icon.module';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, TiimeIconModule, TiimeButtonModule],
  declarations: [SnackbarComponent],
  exports: [SnackbarComponent]
})
export class TiimeSnackbarModule {}
