import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { RedirectIfResourceNotAvailable } from '@core/decorators/redirect-if-resource-not-available';
import { CompanyConfig } from '@core/models/company-config.model';
import { Formality } from '@core/models/formality.model';
import { CompanyConfigApiService } from '@core/services/api/company-config.service';
import { companyConfigSelector } from '@core/store/company-config';
import { formalitySelector } from '@core/store/formality';
import { AppStoreState } from '@interfaces/app-store-state';

import * as CompanyConfigActions from '../store/company-config/company-config-actions';

@Injectable({
  providedIn: 'root'
})
export class CompanyConfigGuard {
  constructor(
    private store: Store<AppStoreState>,
    private companyConfigApiService: CompanyConfigApiService
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(formalitySelector).pipe(
      filter(formality => !!formality),
      take(1),
      switchMap((formality: Formality) => this.hasCompanyConfig(formality.companyCreation.company.id))
    );
  }

  @RedirectIfResourceNotAvailable()
  private getCompanyConfig(companyId: number): Observable<CompanyConfig> {
    return this.companyConfigApiService.getCompanyConfig(companyId);
  }

  private hasCompanyConfigInStore(): Observable<boolean> {
    return this.store.pipe(
      select(companyConfigSelector),
      map((companyConfig: CompanyConfig) => (companyConfig ? true : false)),
      take(1)
    );
  }

  private hasCompanyConfigInApi(companyId: number): Observable<boolean> {
    return this.getCompanyConfig(companyId).pipe(
      tap((companyConfig: CompanyConfig) => this.store.dispatch(CompanyConfigActions.select({ companyConfig }))),
      catchError(() => of(false)),
      switchMap(() => this.hasCompanyConfigInStore())
    );
  }

  private hasCompanyConfig(companyId: number): Observable<boolean> {
    return this.hasCompanyConfigInStore().pipe(
      switchMap((inStore: boolean) => (inStore ? of(inStore) : this.hasCompanyConfigInApi(companyId)))
    );
  }
}
