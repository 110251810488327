import { Action, createReducer, on } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import { CompanyConfig } from '@core/models/company-config.model';

import * as CompanyConfigActions from './company-config-actions';

export type CompanyConfigState = CompanyConfig | null;
export const initialState: CompanyConfigState = null;

const companyConfigReducer = createReducer(
  initialState,
  on(CompanyConfigActions.select, setcompanyConfig),
  on(CompanyConfigActions.update, setcompanyConfig),
  on(CompanyConfigActions.remove, () => null)
);

function setcompanyConfig(_, { companyConfig }: { companyConfig: CompanyConfig }): CompanyConfigState {
  return cloneDeep(companyConfig);
}

export function reducer(state: CompanyConfigState, action: Action): CompanyConfigState {
  return companyConfigReducer(state, action);
}
