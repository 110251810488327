import { UserCapacity } from '@enums/user-capacity.enum';
import { UserType } from '@enums/user-type.enum';
import { DateHelper } from '@helpers/date.helper';

import { PersonBase } from './person-base.model';

export interface MoralPersonJson {
  id: number;
  capacity: UserCapacity;
  capital: number;
  beneficiary: boolean;
  capital_contribution: number;
  director: boolean;
  start_job_date: string;
  end_job_date: string;
  entry_date: string;
  exit_date: string;
  legal_form: string;
  legal_representative_email: string;
  legal_representative_first_name: string;
  legal_representative_last_name: string;
  legal_representative_phone: string;
  mailing_city: string;
  mailing_country: string;
  mailing_postal_code: string;
  mailing_street: string;
  name: string;
  rcs_city: string;
  shares_bare_ownership_number: number;
  shares_number: number;
  shares_percentage: number;
  shares_usufruct_number: number;
  siret: string;
}

export class MoralPerson extends PersonBase {
  constructor(
    public id: number,
    public capacity: UserCapacity,
    public director: boolean,
    public beneficiary: boolean,
    public capitalContribution: number,
    public sharesNumber: number,
    public sharesPercentage: number,
    public capital: number,
    public startJobDate: Date,
    public endJobDate: Date,
    public entryDate: Date,
    public exitDate: Date,
    public legalForm: string,
    public legalRepresentativeEmail: string,
    public legalRepresentativeFirstName: string,
    public legalRepresentativeLastName: string,
    public legalRepresentativePhone: string,
    public mailingCity: string,
    public mailingCountry: string,
    public mailingPostalCode: string,
    public mailingStreet: string,
    public name: string,
    public rcsCity: string,
    public sharesBareOwnershipNumber: number,
    public sharesUsufructNumber: number,
    public siret: string
  ) {
    super(id, capacity, director, beneficiary, capitalContribution, sharesNumber, sharesPercentage);

    this.type = UserType.moral;
  }

  get fullName(): string {
    return this.legalForm ? `${this.name} - ${this.legalForm}` : this.name;
  }

  get email(): string {
    return this.legalRepresentativeEmail;
  }

  get phone(): string {
    return this.legalRepresentativePhone;
  }

  static fromJson(json: Partial<MoralPersonJson>): MoralPerson {
    return new MoralPerson(
      json.id,
      json.capacity,
      json.director,
      json.beneficiary,
      json.capital_contribution,
      json.shares_number,
      json.shares_percentage,
      json.capital,
      json.start_job_date ? DateHelper.fromUtc(json.start_job_date) : null,
      json.end_job_date ? DateHelper.fromUtc(json.end_job_date) : null,
      json.entry_date ? DateHelper.fromUtc(json.entry_date) : null,
      json.exit_date ? DateHelper.fromUtc(json.exit_date) : null,
      json.legal_form,
      json.legal_representative_email,
      json.legal_representative_first_name,
      json.legal_representative_last_name,
      json.legal_representative_phone,
      json.mailing_city,
      json.mailing_country,
      json.mailing_postal_code,
      json.mailing_street,
      json.name,
      json.rcs_city,
      json.shares_bare_ownership_number,
      json.shares_usufruct_number,
      json.siret
    );
  }
}
