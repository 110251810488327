import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ListItemComponent } from './list-item.component';
import { ListComponent } from './list.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ListComponent, ListItemComponent],
  exports: [ListComponent, ListItemComponent]
})
export class TiimeListModule {}
