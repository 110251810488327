import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { StepStatusEnum } from '@enums/step-status.enum';

export const FORMALITY_CARD_DISPLAYED = 'Formality Card Displayed';

export class FormalityCardDisplayed implements TrackingEvent {
  readonly name = FORMALITY_CARD_DISPLAYED;

  constructor(readonly stepStatus: StepStatusEnum) {}
}
