import { StepStatusEnum } from '@enums/step-status.enum';

export const STEP_STATUS_LABEL = {
  [StepStatusEnum.companyCheck]: 'Entreprise',
  [StepStatusEnum.headOfficeCheck]: 'Siège social',
  [StepStatusEnum.activityCheck]: 'Activité',
  [StepStatusEnum.capitalCheck]: 'Capital',
  [StepStatusEnum.fiscalOptionsCheck]: 'Choix fiscaux',
  [StepStatusEnum.responsability]: 'Vos responsabilités',
  [StepStatusEnum.physicalPersonPersonalInfoCheck]: 'Vos données personnelles',
  [StepStatusEnum.physicalPersonContactInfoCheck]: 'Vos coordonnées',
  [StepStatusEnum.physicalPersonEmployedStatusInfoCheck]: 'Votre situation professionnelle',
  [StepStatusEnum.physicalPersonSpouseInfoCheck]: 'Votre conjoint(e)',
  [StepStatusEnum.physicalPersonParentsInfoCheck]: 'Vos Parents'
};
