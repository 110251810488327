import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimePipesModule } from '@manakincubber/tiime-utils';

import { CarouselItemDirective } from './carousel-item.directive';
import { CarouselComponent } from './carousel.component';
import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';

@NgModule({
  imports: [CommonModule, TiimeButtonModule, TiimeIconModule, TiimePipesModule],
  declarations: [CarouselItemDirective, CarouselComponent],
  exports: [CarouselItemDirective, CarouselComponent]
})
export class TiimeCarouselModule {}
