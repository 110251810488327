import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  addBreadcrumb,
  captureException,
  configureScope,
  init,
  instrumentAngularRouting,
  Replay
} from '@sentry/angular-ivy';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { Environment } from '@interfaces/environment';
import { User } from '@models/user.model';

import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  get env(): Environment {
    return this.appConfigService.environment;
  }

  get version(): string {
    return this.appConfigService.version;
  }

  constructor(private appConfigService: AppConfigService) {}

  captureException(error: Error & { originalError?: Error }): void {
    if (this.env?.SENTRY_DSN) {
      captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }

  cleanUser(): void {
    if (this.env.SENTRY_DSN) {
      configureScope(scope => scope.setUser(null));
    }
  }

  init(): void {
    if (this.env.SENTRY_DSN) {
      init({
        integrations: [
          new Replay({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [window.location.origin]
          }),
          new TracingIntegrations.BrowserTracing({
            tracePropagationTargets: [this.env.API_URL],
            routingInstrumentation: instrumentAngularRouting,
            beforeNavigate(context) {
              return {
                ...context,
                name: location.pathname
                  .replace(/\?.*/g, '?[queryParams]') // On remplace les queryParams (/module?myQuery=param -> /module?[queryParams])
                  .replace(/\/\d+/g, '/{id}') // On remplace les ids (/company/1234/module -> /company/{id}/module)
              };
            }
          })
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        dsn: this.env.SENTRY_DSN,
        environment: this.env.ENV_NAME,
        release: this.version,
        ignoreErrors: ['Non-Error exception captured', 'ChunkLoadError'],
        beforeBreadcrumb: (breadcrumb, hint) => {
          if (breadcrumb.category === 'xhr') {
            const data = {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, no-underscore-dangle
              requestBody: hint?.xhr?.__sentry_xhr__?.body,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, no-underscore-dangle
              response: hint.xhr.response,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, no-underscore-dangle
              responseUrl: hint.xhr.responseURL
            };
            return { ...breadcrumb, data };
          }
          return breadcrumb;
        },
        beforeSend(event, hint) {
          const error = hint.originalException;
          if (error && error instanceof HttpErrorResponse) {
            return null;
          }
          return event;
        }
      });
    }
  }

  addBreadcrumb(message: string, data: any = undefined, category: string = 'User action'): void {
    if (!this.env.SENTRY_DSN) {
      return;
    }
    addBreadcrumb({
      message,
      data,
      category,
      level: 'info'
    });
  }

  setUser(user: User): void {
    if (this.env.SENTRY_DSN) {
      configureScope(scope =>
        scope.setUser({
          userId: user.id,
          email: user.email,
          phone: user.phone,
          firstname: user.firstname,
          lastname: user.lastname
        })
      );
    }
  }
}
