import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { update as updateUser, userSelector } from '@core/store/user';
import { User } from '@models/user.model';
import { UserApiService } from '@services/api/user-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard {
  constructor(
    private store: Store,
    private userApiService: UserApiService
  ) {}

  canActivate(): Observable<boolean> {
    return this.hasUserInStore().pipe(switchMap((inStore: boolean) => (inStore ? of(inStore) : this.hasUserInApi())));
  }

  private hasUserInStore(): Observable<boolean> {
    return this.store.pipe(
      select(userSelector),
      map((user: User) => !!user),
      take(1)
    );
  }

  private hasUserInApi(): Observable<boolean> {
    return this.userApiService.getUser().pipe(
      tap(user => this.store.dispatch(updateUser({ user }))),
      catchError(() => of(false)),
      switchMap(() => this.hasUserInStore())
    );
  }
}
