import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';

import { AuthCallbackComponent } from '@core/auth/auth-callback/auth-callback.component';
import { SigninComponent } from '@core/auth/signin/signin.component';
import { CGUGuard } from '@core/guards/cgu.guard';
import { CompanyConfigGuard } from '@core/guards/company-config.guard';
import { FormalityGuard } from '@core/guards/formality.guard';
import { SignedDocumentsGuard } from '@core/guards/signed-documents.guard';
import { UserGuard } from '@core/guards/user.guard';
import { ErrorPageComponent } from '@modules/shared/error-page/error-page.component';
import { AppRoute } from '@navigation/app-route.enum';

const routes: Routes = [
  { path: AppRoute.signin, component: SigninComponent },
  { path: AppRoute.authCallback, component: AuthCallbackComponent },
  {
    path: AppRoute.auth,
    redirectTo: AppRoute.signin // remove when old creations is done
  },
  { path: AppRoute.empty, redirectTo: AppRoute.formalities, pathMatch: 'full' },
  {
    path: AppRoute.formalities,
    canActivate: [AuthGuard, UserGuard, CGUGuard],
    loadChildren: () => import('./formalities/formalities.module').then(m => m.FormalitiesModule)
  },
  {
    path: AppRoute.cgu,
    canActivate: [AuthGuard, UserGuard, SignedDocumentsGuard],
    loadChildren: () => import('./cgu/cgu.module').then(m => m.CGUModule)
  },
  {
    path: AppRoute.formalities + '/' + AppRoute.creation + '/:id',
    canActivate: [AuthGuard, UserGuard, FormalityGuard, CompanyConfigGuard],
    loadChildren: () => import('./creation/creation.module').then(m => m.CreationModule)
  },
  {
    path: AppRoute.error,
    pathMatch: 'full',
    component: ErrorPageComponent
  },
  {
    path: '**',
    redirectTo: AppRoute.formalities
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', canceledNavigationResolution: 'computed' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
