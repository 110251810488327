import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { HasAttributesBase } from '../core';

@Component({
  selector: 'tiime-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['input-container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InputContainerComponent extends HasAttributesBase {
  @Input() contentId: string;
  @Input() label: string;
  @Input() errorMessage: string;
  @Input() suffix: string;

  @HostBinding('class')
  readonly hostClasses = 'tiime-input-container'; //TODO: is it usefull or the selector could be used instead?

  private readonly classList: string[] = ['small', 'italic'];

  constructor(elementRef: ElementRef) {
    super(elementRef);
    this.initClasses();
  }

  private initClasses(): void {
    const itemClasses = this.classList.filter((className: string) => this._hasHostAttributes(className));
    this.elementRef.nativeElement.classList.add(...itemClasses);
  }
}
