import { OriginConnectionPosition, OverlayConnectionPosition } from '@angular/cdk/overlay';

import { TooltipPosition } from './tooltip-position';

export type TooltipConnectionPositionPair = OriginConnectionPosition & OverlayConnectionPosition;

export const TOOLTIP_CONNECTION_POSITION_PAIR: Record<TooltipPosition, TooltipConnectionPositionPair> = {
  top: { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
  bottom: {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top'
  },
  right: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center'
  },
  left: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center'
  },
  above: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom'
  },
  below: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top'
  },
  'below-right': {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top'
  },
  'below-left': {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top'
  },
  'above-left': {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom'
  },
  'above-right': {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  'above-start': {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  'below-start': {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top'
  }
};
