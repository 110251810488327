import { TiimeBusinessRefusalReasonEnum } from '@enums/tiime-business-refusal-reason.enum';

export interface CapitalJson {
  share_capital: number;
  shares_number: number;
  share_value: number;
  capital_deposit_type: string;
  customer_capital_deposit_type: string;
  notary: string;
  notary_address: string;
  tiime_business_release_capital: boolean;
  tiime_business_proposal_user_id: number;
  tiime_business_refusal_reason: TiimeBusinessRefusalReasonEnum;
}

export class Capital {
  private readonly refusalReasons = [
    TiimeBusinessRefusalReasonEnum.traditionnalBankingCompetition,
    TiimeBusinessRefusalReasonEnum.neoBankingCompetition,
    TiimeBusinessRefusalReasonEnum.bankingPartnerRecommendation,
    TiimeBusinessRefusalReasonEnum.other
  ];

  get needToShowTiimeBusiness(): boolean {
    return (
      (this.tiimeBusinessReleaseCapital === null || this.tiimeBusinessReleaseCapital === false) &&
      this.refusalReasons.includes(this.tiimeBusinessRefusalReason)
    );
  }

  constructor(
    public shareCapital: number,
    public sharesNumber: number,
    public shareValue: number,
    public capitalDepositType: string,
    public customerCapitalDepositType: string,
    public notary?: string,
    public notaryAddress?: string,
    public tiimeBusinessReleaseCapital?: boolean,
    public tiimeBusinessProposalUserId?: number,
    public tiimeBusinessRefusalReason?: TiimeBusinessRefusalReasonEnum
  ) {}

  static fromJson(json: CapitalJson): Capital {
    return new Capital(
      json.share_capital,
      json.shares_number,
      json.share_value,
      json.capital_deposit_type,
      json.customer_capital_deposit_type,
      json.notary,
      json.notary_address,
      json.tiime_business_release_capital,
      json.tiime_business_proposal_user_id,
      json.tiime_business_refusal_reason
    );
  }
}
