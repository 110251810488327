import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TableAnchorComponent } from './table-anchor.component';
import { TableContentPlaceholderComponent } from './table-content-placeholder.component';
import { StickyTableDirective } from './table-sticky.directive';
import { TableComponent } from './table.component';

@NgModule({
  imports: [CommonModule, CdkTableModule],
  declarations: [TableComponent, TableContentPlaceholderComponent, TableAnchorComponent, StickyTableDirective],
  exports: [
    CdkTableModule,
    TableComponent,
    TableContentPlaceholderComponent,
    TableAnchorComponent,
    StickyTableDirective
  ]
})
export class TiimeTableModule {}
