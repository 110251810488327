import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogFooterComponent } from './dialog-footer.component';

@NgModule({
  declarations: [DialogFooterComponent],
  imports: [CommonModule],
  exports: [DialogFooterComponent]
})
export class TiimeDialogFooterModule {}
