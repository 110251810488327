import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ProgressBarLoaderComponent } from './progress-bar-loader.component';
import { ProgressSpinnerLoaderComponent } from './progress-spinner-loader.component';

@NgModule({
  imports: [CommonModule, MatProgressBarModule, MatProgressSpinnerModule],
  declarations: [ProgressBarLoaderComponent, ProgressSpinnerLoaderComponent],
  exports: [MatProgressBarModule, ProgressBarLoaderComponent, ProgressSpinnerLoaderComponent]
})
export class TiimeLoaderModule {}
