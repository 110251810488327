export interface CguJson {
  uuid: string;
  type?: string;
  application?: string;
  version?: string;
  created_at?: string;
  expired_at?: string;
  file?: {
    uuid: string;
  };
}

export class Cgu {
  constructor(
    public uuid: string,
    public type?: string,
    public application?: string,
    public version?: string,
    public created_at?: string,
    public expired_at?: string,
    public file?: {
      uuid: string;
    }
  ) {}

  static fromJson(json: CguJson): Cgu {
    return new Cgu(json.uuid, json.type, json.application, json.version, json.created_at, json.expired_at, json.file);
  }

  static toJson(user: Cgu): CguJson {
    return {
      uuid: user.uuid,
      type: user.type,
      application: user.application,
      version: user.version,
      created_at: user.created_at,
      expired_at: user.expired_at,
      file: user.file
    };
  }
}
