import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeNavbarItemComponent } from './navbar-item.component';
import { TiimeNavbarComponent } from './navbar.component';
import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';
import { TiimeTooltipModule } from '../tooltip/index';

@NgModule({
  declarations: [TiimeNavbarComponent, TiimeNavbarItemComponent],
  imports: [CommonModule, TiimeIconModule, TiimeButtonModule, TiimeTooltipModule],
  exports: [TiimeNavbarComponent, TiimeNavbarItemComponent]
})
export class TiimeNavbarModule {}
