import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { User } from '@models/user.model';

import * as UserActions from './user-actions';

export const initialState: User = null;

const userReducer = createReducer(
  initialState,
  on(UserActions.update, (_state, { user }) => cloneDeep(user))
);

export function reducer(state: User | undefined, action: Action): User {
  return userReducer(state, action);
}
