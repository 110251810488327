import { UserCapacity } from '@enums/user-capacity.enum';
import { UserType } from '@enums/user-type.enum';

export abstract class PersonBase {
  public type: UserType;

  constructor(
    public id: number,
    public capacity: UserCapacity,
    public director: boolean,
    public beneficiary: boolean,
    public capitalContribution: number,
    public sharesNumber: number,
    public sharesPercentage: number
  ) {}

  get isMoral(): boolean {
    return this.type === UserType.moral;
  }

  abstract get fullName(): string;
}
