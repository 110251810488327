export type UserIdPrefix = 'utilisateur';
export type CompanyIdPrefix = 'societe';

type PrefixedId<TPrefix extends string> = `${TPrefix}-${number}`;

export const prefixUserId = (id: number): PrefixedId<UserIdPrefix> => toAmplitudeId('utilisateur', id);

export const prefixCompanyId = (id: number): PrefixedId<CompanyIdPrefix> => toAmplitudeId('societe', id);

function toAmplitudeId<TPrefix extends string>(prefix: TPrefix, id: number): PrefixedId<TPrefix> {
  return `${prefix}-${id}`;
}
