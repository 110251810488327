import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeEmptyStateComponent } from './empty-state.component';
import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';

@NgModule({
  imports: [CommonModule, TiimeIconModule, TiimeButtonModule],
  declarations: [TiimeEmptyStateComponent],
  exports: [TiimeEmptyStateComponent]
})
export class TiimeEmptyStateModule {}
