import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  readonly authIsLoading$: Observable<boolean> = this.authService.isLoading$;

  constructor(
    router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loginRequired();
  }

  private loginRequired(): void {
    this.authService
      .loginRequired()
      .pipe(switchMap(() => this.authService.login()))
      .subscribe();
  }
}
