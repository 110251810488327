import { createAction, props } from '@ngrx/store';

import { Formality } from '@models/formality.model';

export const SELECT = '[Formality] Select';
export const UPDATE = '[Formality] Update';
export const PATCH = '[Formality] Patch';
export const REMOVE = '[Formality] Remove';

export const select = createAction(SELECT, props<{ formality: Formality }>());
export const update = createAction(UPDATE, props<{ formality: Formality }>());
export const patch = createAction(PATCH, props<{ formalityPartial: Partial<Formality> }>());
export const remove = createAction(REMOVE);
