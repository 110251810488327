import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TagComponent } from './tag.component';
import { TiimeIconModule } from '../icon/index';

@NgModule({
  imports: [CommonModule, TiimeIconModule],
  declarations: [TagComponent],
  exports: [TagComponent]
})
export class TiimeTagModule {}
