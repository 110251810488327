/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web_legal_app'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 30
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/implementation/web_legal_app)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development' | 'test';

export const ApiKey: Record<Environment, string> = {
  production: 'efdfbac267c729681e8d0816a7dd69c0',
  development: '1611e2699f491246892753e4461f6073',
  test: 'a738b44926ddfebf7226305bb61ea69b'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '30',
    branch: 'main',
    source: 'web_legal_app',
    versionId: '19c5afff-b60a-48c0-ad3b-23cbf3566e9b'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * C'est la business unit dans laquelle travaille le collaborateur de cabinet. Si l'utilisateur est côté entrepreneur le champ est vide.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  business_unit?: string[];
  gclid?: string;
  initial_dclid?: string;
  initial_fbclid?: string;
  initial_gbraid?: string;
  initial_gclid?: string;
  initial_ko_click_id?: string;
  initial_msclkid?: string;
  initial_referrer?: string;
  initial_referring_domain?: string;
  initial_ttclid?: string;
  initial_twclid?: string;
  initial_utm_campaign?: string;
  initial_utm_content?: string;
  initial_utm_id?: string;
  initial_utm_medium?: string;
  initial_utm_source?: string;
  initial_utm_term?: string;
  initial_wbraid?: string;
  /**
   * Cette propriété est réservée aux collaborateurs. Les utilisateurs de type custome ont un champ vide pour cette propriété.
   */
  initiales_collaborateur?: string;
  /**
   * C'est le partner du collaborateur du cabinet. Si l'utilisateur est côté entrepreneur, on laisse le champ vide.
   */
  partner?: string;
  /**
   * Pour distinguer facilement les utilisateurs finaux et les collaborateurs qui interviennent sur le dossier. Doit avoir la valeur collaborateur si l'utilisateur est côté "cabinet"
   */
  type_d_utilisateur?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_id?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

export interface CarteClientFormaliteAfficheeProperties {
  /**
   * Id de la bu du dossier à qui appartient la formalité
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  id_business_unit_invitation_formalite: number;
  /**
   * Cette propriété renseigne le thème de la carte d'une formalité.
   */
  theme_carte_client_formalite: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | creation |
   */
  type_de_formalite: "creation";
}

export interface FormaliteClientAccedeeProperties {
  /**
   * Id de la bu du dossier à qui appartient la formalité
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  id_business_unit_invitation_formalite: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | creation |
   */
  type_de_formalite: "creation";
}

export interface FormaliteClientCommenteeProperties {
  /**
   * **un booléen qui indique si le commentaire validé est vide (ce qui correspond à un événement à ignorer) ou contient du texte.**
   */
  commentaire_formalite_vide: boolean;
  /**
   * Id de la bu du dossier à qui appartient la formalité
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  id_business_unit_invitation_formalite: number;
  /**
   * Cette propriété renseigne le thème de la carte d'une formalité.
   */
  theme_carte_client_formalite: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | creation |
   */
  type_de_formalite: "creation";
}

export interface FormaliteClientSigneeProperties {
  /**
   * Id de la bu du dossier à qui appartient la formalité
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  id_business_unit_invitation_formalite: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | creation |
   */
  type_de_formalite: "creation";
}

export interface ToutesCartesClientFormaliteVuesProperties {
  /**
   * Id de la bu du dossier à qui appartient la formalité
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  id_business_unit_invitation_formalite: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | creation |
   */
  type_de_formalite: "creation";
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CarteClientFormaliteAffichee implements BaseEvent {
  event_type = 'carte_client_formalite_affichee';

  constructor(
    public event_properties: CarteClientFormaliteAfficheeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FormaliteClientAccedee implements BaseEvent {
  event_type = 'formalite_client_accedee';

  constructor(
    public event_properties: FormaliteClientAccedeeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FormaliteClientCommentee implements BaseEvent {
  event_type = 'formalite_client_commentee';

  constructor(
    public event_properties: FormaliteClientCommenteeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FormaliteClientSignee implements BaseEvent {
  event_type = 'formalite_client_signee';

  constructor(
    public event_properties: FormaliteClientSigneeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToutesCartesClientFormaliteVues implements BaseEvent {
  event_type = 'toutes_cartes_client_formalite_vues';

  constructor(
    public event_properties: ToutesCartesClientFormaliteVuesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * carte_client_formalite_affichee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/carte_client_formalite_affichee)
   *
   * **​Si au chargement de la page /informations une carte X est affichée alors un événement** `carte_client_formalite_affichee` **doit être déclenché.**
   *
   * @param properties The event's properties (e.g. id_business_unit_invitation_formalite)
   * @param options Amplitude event options.
   */
  carteClientFormaliteAffichee(
    properties: CarteClientFormaliteAfficheeProperties,
    options?: EventOptions,
  ) {
    return this.track(new CarteClientFormaliteAffichee(properties), options);
  }

  /**
   * formalite_client_accedee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/formalite_client_accedee)
   *
   * Au clic sur le dossier à valider
   *
   * @param properties The event's properties (e.g. id_business_unit_invitation_formalite)
   * @param options Amplitude event options.
   */
  formaliteClientAccedee(
    properties: FormaliteClientAccedeeProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormaliteClientAccedee(properties), options);
  }

  /**
   * formalite_client_commentee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/formalite_client_commentee)
   *
   * Cet event est envoyé quand le Customer entre un commentaire lors de la validation de sa formalité.
   *
   * @param properties The event's properties (e.g. commentaire_formalite_vide)
   * @param options Amplitude event options.
   */
  formaliteClientCommentee(
    properties: FormaliteClientCommenteeProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormaliteClientCommentee(properties), options);
  }

  /**
   * formalite_client_signee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/formalite_client_signee)
   *
   * Cet event est envoyé quand le Customer appose sa signature sur une formalité.
   *
   * @param properties The event's properties (e.g. id_business_unit_invitation_formalite)
   * @param options Amplitude event options.
   */
  formaliteClientSignee(
    properties: FormaliteClientSigneeProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormaliteClientSignee(properties), options);
  }

  /**
   * toutes_cartes_client_formalite_vues
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/toutes_cartes_client_formalite_vues)
   *
   * **​​Si un customer clique sur “Valider les infos” ou sur “Importer mes justificatifs” depuis la page /informations alors un événement** `toutes_cartes_client_formalite_vues` **doit être déclenché.**
   *
   * @param properties The event's properties (e.g. id_business_unit_invitation_formalite)
   * @param options Amplitude event options.
   */
  toutesCartesClientFormaliteVues(
    properties: ToutesCartesClientFormaliteVuesProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToutesCartesClientFormaliteVues(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
