import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Formality } from '@models/formality.model';

export const FORMALITY_OPENED = 'Formality Opened';

export class FormalityOpened implements TrackingEvent {
  readonly name = FORMALITY_OPENED;

  constructor(readonly formality: Formality) {}
}
