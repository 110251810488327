export interface CompanyJson {
  id: number;
  name: string;
  legal_form: string;
  short_name: string;
  sign: string;
  commercial_name: string;
}

export class Company {
  constructor(
    public id: number,
    public name: string,
    public legalForm: string,
    public shortName: string,
    public sign: string,
    public commercialName: string
  ) {}

  static fromJson(json: CompanyJson): Company {
    return new Company(json.id, json.name, json.legal_form, json.short_name, json.sign, json.commercial_name);
  }

  static toJson(model: Company): CompanyJson {
    return {
      id: model.id,
      name: model.name,
      legal_form: model.legalForm,
      short_name: model.shortName,
      sign: model.sign,
      commercial_name: model.commercialName
    };
  }
}
