import { CodeNameEnum } from '@enums/code-name.enum';
import { FormalityTypeEnum } from '@enums/formality-type.enum';

import { Creation, CreationJson } from './creation.model';

export interface FormalityJson {
  id: number;
  code_name: CodeNameEnum;
  formality_type: FormalityTypeEnum;
  company_creation: CreationJson;
  business_unit_id: number;
}

export class Formality {
  constructor(
    public id: number,
    public codeName: CodeNameEnum,
    public formalityType: FormalityTypeEnum,
    public companyCreation: Creation,
    public businessUnitId?: number
  ) {}

  static fromJson(json: FormalityJson): Formality {
    return new Formality(
      json.id,
      json.code_name,
      json.formality_type,
      json.company_creation ? Creation.fromJson(json.company_creation) : null,
      json.business_unit_id
    );
  }
}
