import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ApiCommonService } from '@bases/services-api.base';
import { ApiAlertError } from '@core/decorators/api-alert-error';
import { CompanyConfig, CompanyConfigJson } from '@core/models/company-config.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyConfigApiService extends ApiCommonService<CompanyConfig, CompanyConfigJson> {
  resourceUrl = 'api/v1/legal/user_validation/{companyId}/company_config';

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  fromJson(json: CompanyConfigJson): CompanyConfig {
    return CompanyConfig.fromJson(json);
  }

  toJson(model: CompanyConfig): CompanyConfigJson {
    return null;
  }

  @ApiAlertError()
  getCompanyConfig(companyId: number): Observable<CompanyConfig> {
    return this.http
      .get<CompanyConfigJson>(this.resourceUrl.replace('{companyId}', companyId.toString()))
      .pipe(map((config: CompanyConfigJson) => this.fromJson(config)));
  }
}
