import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeOverlayComponent } from './overlay.component';
import { TiimeOverlayService } from './overlay.service';
import { TiimeIconModule } from '../icon/index';

@NgModule({
  imports: [CommonModule, TiimeIconModule],
  declarations: [TiimeOverlayComponent],
  providers: [TiimeOverlayService],
  exports: [TiimeOverlayComponent]
})
export class TiimeOverlayModule {}
