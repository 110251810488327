import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiAlertError } from '@core/decorators/api-alert-error';
import { Cgu, CguJson } from '@core/models/cgu.model';

@Injectable({ providedIn: 'root' })
export class CguApiService {
  constructor(private http: HttpClient) {}

  @ApiAlertError()
  signedDocumentAgreements(): Observable<Cgu[]> {
    return this.http
      .get<CguJson[]>('api/v1/users/me/signed_document_agreements?applications=legal')
      .pipe(map((cgus: CguJson[]) => cgus.map(cgu => Cgu.fromJson(cgu))));
  }

  @ApiAlertError()
  unsignedDocumentAgreements(): Observable<Cgu[]> {
    return this.http
      .get<CguJson[]>('api/v1/users/me/unsigned_agreement_documents?applications=legal')
      .pipe(map((cgus: CguJson[]) => cgus.map(cgu => Cgu.fromJson(cgu))));
  }

  @ApiAlertError()
  signDocuments(documens: string[]): Observable<unknown> {
    return this.http.post('api/v1/users/me/document_agreements', { file_uuids: documens, application_source: 'legal' });
  }

  @ApiAlertError()
  downloadDocument(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' }).pipe(map(res => new Blob([res], { type: 'application/pdf' })));
  }
}
